import React, { Component, useCallback, useEffect, useState } from 'react'
import { Row, Col, Card, Tab, Tabs, Table, ProgressBar, Modal, Button, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { BsPen } from "react-icons/bs";



import { BASE } from '../../basedata/constants'
import EventGroupCreationWizard from './eventGroupCreationWizard'
import { EventSnippetDetails } from './eventSnipperDetails';


function Events() {

  const dateConvert = (date: any) => {
    const eventDate = new Date(date)

    setEventDataTransformed({
      dataTransformDay: eventDate.getDate().toString().padStart(2, '0'),
      dataTransformMonth: new Intl.DateTimeFormat("pt-BR", { month: "short" }).format(eventDate),
      dataTransformTime: `${eventDate.getHours()}:${eventDate.getMinutes().toString().padStart(2, '0')}`,
    })
    return `${eventDate.toLocaleDateString(
      'pt-br'
    )} ${eventDate.getHours()}:${eventDate.getMinutes().toString().padStart(2, '0')}`
  }

  const snippetDateConvert = (date: any) => {
    const eventDate = new Date(date)

    return {
      dataTransformDay: eventDate.getDate().toString().padStart(2, '0'),
      dataTransformMonth: new Intl.DateTimeFormat("pt-BR", { month: "short" }).format(eventDate),
      dataTransformTime: `${eventDate.getHours()}:${eventDate.getMinutes().toString().padStart(2, '0')}`,
    }
  }
  
  const [eventList, setEventList] = useState<any>([]);

  const [eventData, setEventData] = useState<any>({})
  const [eventDataTransformed, setEventDataTransformed] = useState<any>({})
  const [userBiometryCount, setUserBiometryCount] = useState<any>({})

  const getNextEvent = () => {
    fetch(`${BASE.EVENT.url}${BASE.EVENT.next_event_info}`, {
      method: 'GET',
    })
      .then((resg) => resg.json())
      .then((response) => {
        setEventData(response.event)
        dateConvert(response.event.eventDate)
        getEventUsers(response.event.eventCode)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getEventUsers = (eventId) => {
    fetch(`${BASE.EVENT.url}${BASE.EVENT.event_users_count}/${eventId}`, {
      method: 'GET',
    })
      .then((resg) => resg.json())
      .then((response) => {
        setUserBiometryCount(response)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getAllEvents = () => {
    fetch(`${BASE.EVENT.url}${BASE.EVENT.all_events}`, {
      method: 'GET',
    })
      .then((resg) => resg.json())
      .then((response) => {
        setEventList(response)
      })
      .catch((error) => {
        console.log(error)
      })
  }


  useEffect(() => {
    getNextEvent()
    getAllEvents()
  }, [])


  return (
<>

<Row>
  <Col md={6} xl={8}>

    <h2 className='table-title'>Eventos Ativos</h2>
    <div>
      {eventData && (
        <div className="event-action-top">

          <div onClick={() => window.location.href = `/events/providers/${eventData.eventCode}`} className="square-button call-buttom rounded-sm">
            <i className='custom-icon'>
              <BsPen />
            </i>
            <span>Editar</span>
          </div>
          <div className="event-action-top-info">
            <div className="square-button rounded-sm">
              <span className="event-info-month">
                {eventDataTransformed.dataTransformMonth}
              </span>
              <span className="event-info-date">
                {eventDataTransformed.dataTransformDay}
              </span>
            </div>
            <div className="square-button event-title rounded-sm">
              <span className="event-info-time">
                {eventDataTransformed.dataTransformTime}
              </span>
              <span className="event-info-title">
                {eventData.title}
              </span>

            </div>
          </div>
        </div>
      )}
    </div>

    <div>
      {eventList.map((item) => {
        const eventDate = new Date(item.eventDate);
        const currentDate = new Date();
        const isActiveEvent = eventDate >= currentDate;

        if (isActiveEvent) {
          return (
            <EventSnippetDetails
              key={item.id}
              eventCode={item.id}
              eventDataTransformed={snippetDateConvert(item.eventDate)}
              eventTitle={item.name}
              eventStatus={item.status}
            />
          );
        }
        return null;
      })}
    </div>

    <h2 className='table-title'>Eventos Passados</h2>
    <div>
      {eventList.map((item) => {
        const eventDate = new Date(item.eventDate);
        const currentDate = new Date();
        const isPastEvent = eventDate < currentDate;
        if (isPastEvent) {
          return (
            <EventSnippetDetails
              key={item.id}
              eventCode={item.id}
              eventDataTransformed={snippetDateConvert(item.eventDate)}
              eventTitle={item.name}
              eventStatus={item.status}
            />
          );
        }
        return null;
      })}
    </div>
  </Col>


  <Col md={6} xl={4}>
    <EventGroupCreationWizard />
  </Col>
</Row>

</>


  )
}

export default Events

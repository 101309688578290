import React, { useEffect, useState } from 'react';
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from 'cdbreact';
import { NavLink } from 'react-router-dom';
import './sidebar.sass'

import { BsCalendar2Event } from "react-icons/bs";
import { LiaUsersCogSolid } from "react-icons/lia";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { GoGraph } from "react-icons/go";

import bepassLogo from '../assets/images/bepass_logo_default.png'
import tokioLogo from '../assets/images/logo-arena-mrv.png'
import fanLogo from '../assets/images/fanbase-logo.png'

const SideBar = () => {

  const activeRole = sessionStorage.getItem('role')
  const sessionId = sessionStorage.getItem('id')
  const sessionStatus = sessionStorage.getItem('status')
  const sessionName = sessionStorage.getItem('name')
  const [orgLogo, setOrgLogo] = useState(tokioLogo)


  function logMeOut() {
    sessionStorage.removeItem('auth')
    sessionStorage.removeItem('gate')
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('name')
    sessionStorage.removeItem('role')
    sessionStorage.removeItem('company')
    return (window.location.href = '/')
  }

  useEffect(() => {
    const companyId = sessionStorage.getItem('company')
    if (companyId === '8e0638ba-bad9-4218-ad65-d0dbb2ea6fa6') {
      setOrgLogo(fanLogo)
    }

  }, [])

  return  sessionStatus !== 'toActive' ? (
    <div className="custom-navBar">
      <CDBSidebar
        breakpoint={991}
        className="my-sidebar"
        textColor="white"
        backgroundColor="black"
        toggled={true}
        minWidth={"100"}
        maxWidth={"300"}
      >

        <CDBSidebarContent className="sidebar-content">
          <CDBSidebarMenu>

          {/* <NavLink to="/dashboard/coordinator" >
                    <li>
                        <span className="sidebar-icon"><PiHouseSimple/></span>
                        <span className='item-content'>Home</span>
                    </li>
                  </NavLink>
 */}


                <NavLink to="/providers" >
                <li>
                        <span className="sidebar-icon"><AiOutlineUsergroupAdd/></span>
                        <span className='item-content'>Camarotes</span>
                    </li>
                </NavLink>


                {sessionName !== 'beops' &&
                  <NavLink to="/events" >
                    <li>
                        <span className="sidebar-icon"><BsCalendar2Event/></span>
                        <span className='item-content'>Eventos</span>
                    </li>
                </NavLink>
                }


                {sessionName !== 'beops' &&
                <NavLink to="/user/admin" >
                    <li>
                        <span className="sidebar-icon"><LiaUsersCogSolid/></span>
                        <span className='item-content'>Usuários</span>
                    </li>
                </NavLink>
                }

              {sessionName !== 'beops' &&
                <NavLink to="/graph" >
                    <li>
                        <span className="sidebar-icon"><GoGraph/></span>
                        <span className='item-content'>Gráficos</span>
                    </li>
                </NavLink>
                }
                   {/* <NavLink to="/user/block" >
                    <li>
                        <span className="sidebar-icon"><TbLockCog/></span>
                        <span className='item-content'>Block list</span>
                    </li>
                </NavLink> */}
        </CDBSidebarMenu>
        </CDBSidebarContent>
      </CDBSidebar>
    </div >
  ) : null;
};


export default SideBar;

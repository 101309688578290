import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Card } from 'react-bootstrap';

const WelcomeModal = ({ show, onHide }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header  className='border-dark text-dark' closeButton>
        <Modal.Title>Olá! Bem-vindo(a)!</Modal.Title>
      </Modal.Header>
      <Modal.Body className='border-dark'>
        <h6 className='text-dark'>Parece que esta é a sua primeira vez aqui.</h6>
        <br/>
        <h6 className='text-dark'>Por favor, <strong className='text-dark' >altere sua senha</strong> para continuar.</h6>
        <br/>
        <h6 className='text-dark'>Após alterar sua senha, <strong className='text-dark' >será necessário fazer login novamente </strong> na plataforma para garantir a segurança de sua conta.</h6>
      </Modal.Body>
    </Modal>
  );
};

WelcomeModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default WelcomeModal;

import React from 'react';
import PropTypes from 'prop-types';
import { BASE } from '../../basedata/constants';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { Button, InputGroup } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { PiMagnifyingGlassLight } from "react-icons/pi";

const EventCreationForm = ({ formData, handleChange, nextStep }) => {
  const { 
    register, 
    handleSubmit, 
    formState: { errors } 
  } = useForm();

  const MySwal = withReactContent(Swal);

  const errorAddEventModal = () => {
    MySwal.fire({
      icon: "error",
      title: "Algo não deu certo!",
      text: "Atualize a pagina e tente novamente",
    });
  }
  
  const onSubmit = (data) => {
    const companyId = sessionStorage.getItem('company');

    fetch(`${BASE.api.base_url_localhost}${BASE.api_dashboard.event_group_create}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': BASE.api.api_key
      },
      body: JSON.stringify({
        "name": data.eventName,
        "status": "active",
        "isActive": true,
        "companyId": companyId,
        "eventType": formData.eventType
      })
    })
    .then((res) => {
      if(!res.ok){
        throw new Error('Erro ao enviar a solicitação: ' + res.status);
      }
      return res.json()
    })
    .then((response) => {
      formData.eventGroup = response.event;
      nextStep()
    })
    .catch((error) => {
      errorAddEventModal();
      console.log(error);
    })
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="table-action-search mb-3">
          <PiMagnifyingGlassLight/>
          <input 
              type="text" 
              placeholder="Nome do Evento:" 
              className="form-control d-inline" 
              id="eventName" 
              value={formData.eventName} 
              {...register("eventName", { required: true })} 
              onChange={handleChange}
          />
          {errors.eventName && 
            <span className="label-error">
              Este campo é obrigatório
            </span>
          }
        </div>
        <div className="text-center">
          <Button type="submit" className="mb-0 w-100">Avançar</Button>
        </div>
      </form>
    </div>
  );
};

EventCreationForm.propTypes = {
  formData: PropTypes.shape({
    eventName: PropTypes.string,
    eventType: PropTypes.string,
    eventGroup: PropTypes.string
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
};

export default EventCreationForm;

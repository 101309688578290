import React, { useEffect, useState } from 'react';
import { faker } from '@faker-js/faker';
import './GraphPage.sass'

import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'


interface DadosAcesso {
  hora: number
  minuto: number;
  acessos: number;
}

const Table3 = () => {
  const [dadosAcessosPorMinuto, setDadosAcessosPorMinuto] = useState<DadosAcesso[]>([]);
  const [recordeAcesso, setRecordeAcesso] = useState<any | null>(null);


  const dateConvert = (date: any) => {
    const eventDate = new Date(date)

    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
    }

    return `${eventDate.getHours()}:${eventDate.getMinutes().toString().padStart(2, '0')}`
}

  const getAccessGroup = () => {

    fetch(`${BASE.GRAPHY.url}${BASE.GRAPHY.getAccessGroup}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: CONFIG.API_KEY
        }
    })
        .then((resg) => resg.json())
        .then((response) => {
          setDadosAcessosPorMinuto(response.slice(0, 10))
        })
        .catch((error) => {
            console.log(error)
        })
}


const getAcessRecord = () => {

  fetch(`${BASE.GRAPHY.url}${BASE.GRAPHY.getAccessGroupRecord}`, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          Authorization: CONFIG.API_KEY
      }
  })
      .then((resg) => resg.json())
      .then((response) => {
        setRecordeAcesso(response)
      })
      .catch((error) => {
          console.log(error)
      })
}



  useEffect(() => {
    const gerarDadosAcessosPorMinuto = () => {
      const novosDados: DadosAcesso[] = [];
  
      for (let i = 0; i < 10; i++) {
        const hour = Math.floor(Math.random() * 24).toString().padStart(2, '0');
        const minute = Math.floor(Math.random() * 60).toString().padStart(2, '0');
  
        const acessos = faker.datatype.number({ min: 0, max: 100 }); 
        novosDados.push({ hora: parseInt(hour), minuto: parseInt(minute), acessos });
      }
  
      const minutoMaiorAcesso = novosDados.reduce((anterior, atual) => {
        return anterior.acessos > atual.acessos ? anterior : atual;
      });
  
      // setRecordeAcesso(minutoMaiorAcesso);
      // setDadosAcessosPorMinuto(novosDados);
    };
    
  

    // getAccessGroup();
    // getAcessRecord();
    // gerarDadosAcessosPorMinuto();
    

    setInterval( () => {
      getAccessGroup();
      getAcessRecord();
      gerarDadosAcessosPorMinuto();
    }
      
      , 10000);

  }, []);
  
  return (
    <div>
    {recordeAcesso && (
      <div className='text-center'>
        <h5 className="text-white bg-dark bg-gradient"  style={{ width:'300px'}}>Recorde de Acesso</h5>
        <div className="row">
          <div className="col">          
            <div className="back-gradiente">
              <h1 className="text-white">{dateConvert(recordeAcesso.entrance_time)}</h1>
            </div>
          </div>
          <div className="col">
            <div className="back-gradiente">
              <h1 className="text-white">{recordeAcesso.count}</h1>
            </div>
          </div>
        </div>
      </div>
)}

    <h4 className='text-white text-center mt-3 h6'>Acessos por Minuto</h4>
      <table className='table table-bordered table-hover border-dark text-center' style={{ fontSize: '0.6rem' }}>
        <thead>
          <tr>
            <th className='bg-dark text-white border-dark'>Hora</th>
            <th className='bg-dark text-white border-dark'>Acessos</th>
          </tr>
        </thead>
        <tbody>
          {dadosAcessosPorMinuto.map((item:any, index) => (
            <tr key={index}>
              <td className='text-white border-dark table-back' style={{ backgroundColor: 'rgba(78, 194, 124, 0.1)' }}>{dateConvert(item.entrance_time)}</td>
              <td className='text-white border-dark' style={{ backgroundColor: 'rgba(78, 194, 124, 0.1)' }} >{item.count}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table3;


import React, { useState } from 'react'
import {
    Button, Card, Col, Row, Form
} from 'react-bootstrap'
import { auth } from "./../../config/firebase";
import { useForm } from 'react-hook-form'
import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import DatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'
import './visitorCreate.sass'
import 'react-datepicker/dist/react-datepicker.css'
import { PiMagnifyingGlassLight } from "react-icons/pi";


registerLocale('pt-br', ptBR)

const isValidEmail = (email: any) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
    )

const strongPassword = (password: any) => {
    return /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(
        password
    )
}

export function UserCreateAdmin() {
    const {
        register,
        handleSubmit,
        formState: { errors },
        clearErrors,
        reset
    } = useForm()

    const [startDate, setStartDate] = useState(new Date())

    const [hasToken, setHasToken] = useState('')

    const [disableSign, setDisableSign] = useState(false)
    const [userCreated, setUserCreated] = useState(false);
    const [newUser, setNewUser] = useState({
        login: '',
        password: '',
        set: false
    })

    function string_to_slug(str: any) {
        str = str.replace(/^\s+|\s+$/g, '') // trim
        str = str.toLowerCase()

        // remove accents, swap ñ for n, etc
        const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
        const to = 'aaaaeeeeiiiioooouuuunc------'
        for (let i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
        }

        str = str
            .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, '_') // collapse whitespace and replace by -
            .replace(/-+/g, '_') // collapse dashes

        return str
    }

    const onSubmit = (data: any) => {
      const companyId = sessionStorage.getItem('company')

      fetch(`${BASE.COMPANY.url}${BASE.COMPANY.create_users}`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'x-api-key': BASE.api.api_key
          },
          body: JSON.stringify({
              name: data.name.replace(/^\s+|\s+$/g, ''),
              recoveryKey: data.document.replace(/^\s+|\s+$/g, ''),
              email: data.email.replace(/^\s+|\s+$/g, ''),
              login: data.email.replace(/^\s+|\s+$/g, ''),
              password: data.password,
              role: 'coordinator',
              companyId: companyId,
              userId: 'ef6c32d0-b113-48fc-8d78-3de22b1ba3a3',
          })
      })
      .then((resg) => resg.json())
      .then((response) => {
        setUserCreated(true);
        reset();
        setTimeout(() => {
          setUserCreated(false);
        }, 3000);
      })
      .catch((error) => {
        window.location.reload()
        console.log(error)
      })
  }

  const resetForm = () => {
      setNewUser({
          login: '',
          password: '',
          set: false
      })
      setHasToken('')
      setDisableSign(false)
      reset()
  }

  return (
    <Card>
    <Card.Header>
        <Card.Title as="h5">Cadastrar um novo usuário</Card.Title>
    </Card.Header>
    <Card.Body>
        <Row>
            <Col>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row className="mb-3">
                        <Col>
                            <Form.Label>Nome</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nome"
                                {...register('name', { required: true })}
                            />
                            {errors.name && <Form.Text className="text-danger">O nome é obrigatório.</Form.Text>}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <Form.Label>E-mail</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="E-mail"
                                {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
                            />
                            {errors.email && <Form.Text className="text-danger">O email é obrigatório e deve ser válido.</Form.Text>}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <Form.Label>CPF</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="CPF"
                                {...register('document', { required: true })}
                            />
                            {errors.document && <Form.Text className="text-danger">O CPF é obrigatório.</Form.Text>}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <Form.Label>Senha temporária</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Senha temporária"
                                {...register('password', { required: true })}
                            />
                            {errors.document && <Form.Text className="text-danger">A senha é obrigatória.</Form.Text>}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Button
                                    disabled={disableSign}
                                    type="submit"
                                    className="mb-0 w-100"
                                    onClick={() => clearErrors()}
                                >
                                    Cadastrar Usuário
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                  {userCreated && <p className="text-center text-light mt-2 bg-success">Usuário criado com sucesso!</p>}
                </Form>
            </Col>
        </Row>
    </Card.Body>
</Card>

  );
}

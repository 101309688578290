import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
  FormControl,
  DropdownButton,
  FloatingLabel,
  Dropdown,
  Table,
  Alert
} from 'react-bootstrap'

import './editUser.sass'

import { useForm } from 'react-hook-form'
import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import DatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'

registerLocale('pt-br', ptBR)

import 'react-datepicker/dist/react-datepicker.css'
import { useParams } from 'react-router-dom'
import WelcomeModal from './welcomeModal'


const isValidEmail = (email: any) =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  )

const strongPassword = (password: any) => {
  return /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(
    password
  )
}

const handleLogout = () => {
  sessionStorage.clear();
    window.location.href = '/';
};

 export function UserEdit() {

  const sessionToken =  sessionStorage.getItem('token')
  const sessionName =  sessionStorage.getItem('name')
  const sessionStatus = sessionStorage.getItem('status')
  const sessionId= sessionStorage.getItem('id')

  let { id } = useParams()

  if (id === 'me') {
    id = sessionStorage.getItem('userId') || 'notfound'
  }

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    getValues,
    reset
  } = useForm()

  const [userData, setUserData] = useState<{ [key: string]: any }>({})
  const [hasToken, setHasToken] = useState('')
  const [disableSign, setDisableSign] = useState(false)
  const [userUpdated, setUserUpdated] = useState(false)
  const [passwordRules, setPasswordRules] = useState(true)
  const currentUser = sessionStorage.getItem('role')
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);

  const onSubmit = (data: any) => {
    setUserUpdated(false)

    reset({
      password: '',
      repassword: ''
    });

    if (!strongPassword(data.password)) {
      setError('password', {
        type: 'manual',
        message: 'Você precisa informar uma senha válida'
      })
      return false
    }

    if (data.password && data.password !== data.repassword) {
      setError('password', {
        type: 'manual',
        message: 'Você precisa informar uma senha válida'
      })

      return false
    }

    fetch(`${BASE.COMPANY.url}${BASE.COMPANY.updateUser}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': `${sessionToken}`
      },
      body: JSON.stringify({
        email: userData.email,
        password: data.password,
        name: userData.displayName
      })
    })
    .then((res) => {
      if (res.status === 200) {
          console.log("Usuário atualizado com sucesso!");
          setUserUpdated(true);

          setTimeout(() => {
              setUserUpdated(false);
          }, 3000);

          if (sessionStatus === 'toActive') {
            updateStatus();
            setTimeout(()=>{
              handleLogout()
            }, 1500);
          }
      } else {
          return res.json();
      }
    })
    .catch((error) => {
        console.log(error);
    });

  }

  const getUserToEdit = () => {
    fetch(`${BASE.COMPANY.url}${BASE.COMPANY.get_user_by_userId}/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then((res) => res.json())
       .then((response) => {
        setUserData(response)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const updateStatus = () => {
    fetch(`${BASE.COMPANY.url}${BASE.COMPANY.update_status}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: sessionId ,
        status: 'tutorial'
      })
    })
      .then((res) => res.json())
       .then((response) => {
        setUserData(response)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    getUserToEdit()
  }, [])

  useEffect(() => {
    getUserToEdit();
    if (sessionStatus === 'toActive') {
      setShowWelcomeModal(true);
    }
  }, [sessionStatus])

  const handleCloseWelcomeModal = () => {
    setShowWelcomeModal(false);
  };

  return (
    <>
    <Card className="card">
      <Card.Header>
        <Card.Title as="h5">Editar Usuário</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <Form onSubmit={handleSubmit(onSubmit)}>
              {currentUser !== 'default' && (
                <Row className="mb-3">
                  <Col>
                    <Form.Label>Login</Form.Label>
                    <Form.Control
                      disabled autoComplete="off"
                      defaultValue={userData.email}
                      placeholder="Login"
                      {...register('login')}
                    />
                  </Col>
                </Row>
              )}
              {currentUser !== 'default' && (
                <Row className="mb-3">
                  <Col>
                    <Form.Label>Nome</Form.Label>
                    <Form.Control
                      disabled autoComplete="off"
                      defaultValue={userData.displayName}
                      placeholder="Nome"
                      {...register('name')}
                    />
                  </Col>

                  <Col>
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control
                      disabled autoComplete="off"
                      defaultValue={userData.email}
                      type="email"
                      placeholder="E-mail"
                      {...register('email')}
                    />
                    {errors.email && (
                      <span className="label-error">
                        Você precisa informar um E-mail válido
                      </span>
                    )}
                  </Col>
                </Row>
              )}
              <Row className="mb-5">
                <Col>
                  <Form.Label>Nova Senha</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Nova Senha"
                    {...register('password')}
                  />
                </Col>
                <Col>
                  <Form.Label>Confirmar Senha</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Confirmar Senha"
                    {...register('repassword')}
                  />
                </Col>
                {errors.password && (
                  <span className="label-error">
                    As senhas precisam ser iguais e sequir os requisitos abaixo:
                  </span>
                )}

                <ul className="password-rules">
                  <li>• O tamanho da senha deve ser maior ou igual a 8</li>
                  <li>• A senha deve conter um ou mais caracteres maiúsculos</li>
                  <li>• A senha deve conter um ou mais caracteres minúsculos</li>
                  <li>• A senha deve conter um ou mais valores numéricos</li>
                  <li>• A senha deve conter um ou mais caracteres especiais</li>
                </ul>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Button
                      disabled={disableSign}
                      type="submit"
                      className="mb-0 w-100 button"
                      onClick={() => clearErrors()}
                    >
                      Editar Usuário
                    </Button>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>

        {userUpdated && (
          <Alert className="mt-5" variant="success">
            Usuário Atualizado!
          </Alert>
        )}
      </Card.Body>
    </Card>
    <WelcomeModal show={showWelcomeModal} onHide={handleCloseWelcomeModal} />
    </>
  )
}

import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
  FormControl,
  DropdownButton,
  Dropdown,
  Table
} from 'react-bootstrap'

import { BASE } from '../../basedata/constants'

import graphTest from '../../images/test-graph.png'

interface Props {
  supplierName: any,
  supplierId: any,
  eventId: any
}


import { PiMegaphoneLight } from "react-icons/pi";
import { GoChevronDown } from "react-icons/go";
import { PiMagnifyingGlassLight } from "react-icons/pi";
import { LiaFileDownloadSolid } from "react-icons/lia";
import { LiaTimesSolid } from "react-icons/lia";
import { TbDoorEnter } from "react-icons/tb";
import { BsPen } from "react-icons/bs";
import { AiOutlineUsergroupAdd, AiOutlineForm, AiOutlineMinusCircle } from "react-icons/ai";
import { MdOutlineFileDownload } from "react-icons/md";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export function SupplierUsersCountItemList({ supplierName, supplierId, eventId }: Props) {

  const [supplierStatus, setSupplierStatus] = useState<any>({})

  const getSupplierUsersStatus = () => {
    fetch(`${BASE.EVENT.url}${BASE.EVENT.event_supplier_status}/${eventId}/${supplierId}`, {
      method: 'GET',
    })
      .then((resg) => resg.json())
      .then((response) => {
        setSupplierStatus(response)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    getSupplierUsersStatus()
  }, [])


 
  const data = {
    // labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
      {
        label: 'Pessoas',
        data: [supplierStatus.biometryCount || 0, supplierStatus.userCount || 0],
        backgroundColor: [
          'rgba(69, 185, 235, 1)',
          'rgba(180, 180, 180, 0.2)',
        ],
        spacing: 5,
        weight: 8,
        cutout: '80%'
        // clip: {left: 15, top: false, right: -2, bottom: 0}
      },
    ],
 
  };

  const options:any = {
    cutout: {
      percentage: 80, // Adjust this value to control the thickness of the donut ring
    },
  };


  const deleteProviderFromEvent = () => {

    fetch(`${BASE.EVENT.url}${BASE.EVENT.event_remove_supplier}`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'x-api-key': BASE.api.api_key
      },
      body: JSON.stringify({
          eventId: eventId,
          groupId: supplierId,
      })
  })
      .then((res) => res.json())
      .then((response) => {
          window.location.reload();
      })
      .catch((error) => {
          console.log(error)
      })

  }

  return (
  <li key={supplierId} className='small-numbers'>
    <div className="small-numbers-content">
    <div className="small-chart">
      <div className="chart-values">{Math.round((supplierStatus.biometryCount || 0 / supplierStatus.userCount || 0) * 100)}%</div>
      <Doughnut data={data} />
    </div>
    <div className='chart-title'>
    {supplierName}
    </div>

    <div className="small-description">
      <div className="small-refference-numbers">
      <div>
      <span className="numbers-value">
          <span className='numbers-value-off'>{supplierStatus.userCount}/</span>{supplierStatus.limit}</span>
        <span>Pessoas</span>
      </div>
      <div>
        <span className="numbers-value">
          <span className='numbers-value-off'>{supplierStatus.biometryCount}/</span>{supplierStatus.userCount}</span>
        <span>Biometrias</span>
      </div>
      </div>
    </div>
    <ul className="group-action">
      <li onClick={() => window.location.href = `/provider/events/${eventId}/${supplierId}`}>
          <span className="sidebar-icon"><PiMegaphoneLight/></span>
      </li>
      {/* <li>
          <span className="embed-icon sidebar-icon"> <MdOutlineFileDownload/> Relatório</span>
      </li> */}
    </ul>
    <div className='event-ticket-button'>
      <div onClick={deleteProviderFromEvent} className="event-icon">
        excuir
      </div>
    </div>
    </div>
  </li>
  )
}

import React, { Component, useCallback, useEffect, useState } from 'react'
import { Row, Col, Card, Tab, Tabs, Table, ProgressBar, Modal, Button, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { AiOutlineUsergroupAdd, AiOutlineForm, AiOutlineMinusCircle } from "react-icons/ai";

import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import { useForm } from 'react-hook-form'

import { ProviderCreate } from './createProvider'
import { CoordinatorRow } from '../components/coordinatorRow'


const maskPhone = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d{4})(\d)/, '$1-$2')
}

function Providers() {

  const companyId = sessionStorage.getItem('company')


  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    getValues,
    reset
  } = useForm()


  const [disableSign, setDisableSign] = useState(false)

  const [supplierList, setSupplierList] = useState<any[]>([])
  const [isLoading, setLoading] = useState(false)
  const [totalUsers, setTotalUsers] = useState({
    withBiometry: 0,
    withoutBiometry: 0
  })

  const [show, setShow] = useState(false);
  const [currentSupplierId, setCurrentcurrentSupplierId] = useState();
  const [currentSupplier, setCurrentcurrentSupplier] = useState<any>();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showOrg, setShowOrg] = useState(false);
  const handleCloseOrg = () => setShowOrg(false)
  const [showConfirm, setShowConfirm] = useState(false);
  const handleShowConfirm = () => setShowConfirm(true)
  const handleCloseConfirm = () => setShowConfirm(false)
  const handleShowOrg = () => setShowOrg(true);

  const [selectedSupplierId, setSelectedSupplierId] = useState<string>('');

  const handleOpenModal = (type: string, userId?: string) => {
    if (type === 'supplier') {
       setSelectedSupplierId(userId || '');
       const currentSupplier = supplierList.find(supplier => supplier.id === userId);
       if (currentSupplier) {
         reset({
           name: currentSupplier.name,
           cnpj: currentSupplier.cnpj,
           trade_name: currentSupplier.trade_name,
           seats: currentSupplier.seats ? currentSupplier.seats : 0,
         });
       }
       setShowOrg(true);
    } else if (type === 'coordinator') {
       setSelectedSupplierId(userId || '');
       handleShow();
    }
   };

  const inactvate = () => {
    setLoading(true)

    fetch(`${BASE.USER.url}${BASE.USER.supplier_inactivate}/${currentSupplier.id}`, {
      method: 'POST',
      headers: {
        Authorization: CONFIG.API_KEY
      }
    })
      .then((resg) => resg.json())
      .then((response) => {
        setLoading(false)
        window.location.reload()


      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
      })
  }

  const getAllSuppliers = () => {
    setLoading(true)

    fetch(`${BASE.USER.url}${BASE.USER.company_suppliers}/${companyId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: CONFIG.API_KEY
      }
    })
      .then((resg) => resg.json())
      .then((response) => {
        setLoading(false)
        setSupplierList(response.suppliers)
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
      })
  }

  const updateSuppliers = (data) => {
    setLoading(true)
    const id = data.supplierId

    fetch(`${BASE.USER.url}${BASE.USER.supplier}/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: CONFIG.API_KEY
      },
      body: JSON.stringify({
        "name": data.name,
        "trade_name": data.trade_name,
        "cnpj": data.cnpj,
        "seats": data.seats
      })
    })
      .then((resg) => resg.json())
      .then((response) => {
        setLoading(false)
        window.location.reload()
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
      })
  }

  const handleUpdate = (data, event) => {
    event.preventDefault();
    updateSuppliers(data)
  };

  const onSubmit = (data: any) => {
    setDisableSign(true)

    fetch(`${BASE.api.base_url}${BASE.api.access_create}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': BASE.api.api_key
      },
      body: JSON.stringify({
        "name": "vasss planeta",
        "trade_name": "Aux-limpeza",
        "cnpj": "0000000000000000",
        "logo": "meu/logo",
        "logo_square": "versao/square",
        "main_color": "#ff00ff",
        "secondary_color": "#ff00ff",
        "status": "active",
        "isActive": true,
        "companyId": companyId,
      })
    })
      .then((res) => res.json())
      .then((response) => {
        console.log(response)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleCreateCoordinator = (data, event) => {
    event.preventDefault();
    createCoordinator(data);
  };

  const createCoordinator = (data: any) => {
    setDisableSign(true)

    const temp_name = data.name.replace(/^\s+|\s+$/g, '').split(' ')

    const temp_password = `tB${Math.random().toString(36).slice(-8)}!`

    let userId;

    fetch(`${BASE.USER.url}${BASE.api_user.user_create}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': BASE.api.api_key
      },
      body: JSON.stringify({
        firstName: temp_name[0],
        lastName: temp_name.slice(1).join(" "),
        email: data.email.replace(/^\s+|\s+$/g, '') || 'a@a.com',
        phoneNumber: data.phoneNumber
          .replace(/"/g, '')
          .replace(/'/g, '')
          .replace(/\(|\)/g, ''),
        "document": data.document.replace(/^\s+|\s+$/g, ''),
        "document_type": "CPF",
        "operator": true,
        "supplierId": data.supplierId,
        "originCompanyId": companyId,
        "externalId": "provider",
        "gender": "na",
        "birthDate": "1990-01-01"
      })
    })
      .then((res) => res.json())
      .then((response) => {

        userId = response.sup
        console.log(response)
        fetch(`${BASE.COMPANY.url}${BASE.api_user.role}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': BASE.api.api_key
          },
          body: JSON.stringify({
            "userId": userId,
            "role": "supplier",
            "companyId": data.supplierId,
            "companyName": "BePass",
            "email": data.email,
            "name": data.name,
            "login": data.email.replace(/^\s+|\s+$/g, ''),
            "password": temp_password,
            "sendMail": true
          })
        })
        .then((response) => {
          window.location.reload()
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const resetForm = () => {
    setDisableSign(false)
    reset()
  }


  useEffect(() => {
    getAllSuppliers()
  }, [])

  return (
    <>
      <Row>
        <Col md={6} xl={8}>

          <h2 className='table-title'>Camarotes</h2>

          <div className="group-wrap">
            <ul className="group-list">
              {supplierList &&
                supplierList.map((user: any, index) => (
                  <li key={index} className='group-item'>

                    <div className="group-detail">
                      <h3 className="group-name">
                        {user.name}
                        <span> |  {user.seats || 0} assentos</span>
                      </h3>
                      <ul className="group-action">



                        <li>
                          <span className="sidebar-icon" onClick={() => handleOpenModal('supplier', user.id)}>
                            <AiOutlineForm />
                          </span>
                        </li>
                        <li onClick={() => handleOpenModal('coordinator', user.id)}>
                          <span className="no-icon sidebar-icon">Criar coordenador</span>
                        </li>
                        <li onClick={() => { setCurrentcurrentSupplier(user); handleShowConfirm() }} >
                          <span className="sidebar-icon icon-red"><AiOutlineMinusCircle /></span>
                        </li>
                      </ul>
                    </div>

                    <div className="group-coordinators">
                      <CoordinatorRow supplierId={user.id} companyId={companyId} />
                    </div>

                  </li>

                ))}
            </ul>
          </div>

        </Col>
        <Col md={6} xl={4}>
          <ProviderCreate />
        </Col>

      </Row>
      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={(event) => handleCreateCoordinator({ ...getValues(), supplierId: selectedSupplierId }, event)}>
          <Form.Control
            type="hidden"
            defaultValue={currentSupplierId}
            {...register('id')}
          />

          <Modal.Header closeButton>
            <Modal.Title>Criar coordenador</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-3">

              <Col>
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  placeholder="Nome Completo"
                  {...register('name', { required: true })}
                />
                {errors.name && (
                  <span className="label-error">
                    Você precisa informar um Nome.
                  </span>
                )}</Col>
            </Row>
            <Row className="mb-3">

              <Col>
                <Form.Label>CPF</Form.Label>

                <Form.Control
                  type="text"
                  placeholder="Documento (CPF)"
                  {...register('document', { required: true })}
                />
                {errors.document && (
                  <span className="label-error">
                    Você precisa informar um CPF.
                  </span>
                )}</Col>
            </Row>
            <Row className="mb-3">
              <Col><Form.Label>E-mail</Form.Label>

                <Form.Control
                  type="email"
                  placeholder="E-mail"
                  {...register('email', { required: true })}
                />
                {errors.email && (
                  <span className="label-error">
                    Você precisa informar um E-mail válido
                  </span>
                )}</Col>

            </Row>
            <Row className="mb-3">
              <Col><Form.Label>Telefone (opcional)</Form.Label>

                <Form.Control
                  type="text"
                  placeholder="Telefone com DDD"
                  {...register('phoneNumber')}
                  onChange={(event) => {
                    const { value } = event.target
                    event.target.value = maskPhone(value)
                  }}
                /></Col>

            </Row>


          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fechar
            </Button>
            <Button variant="primary" type='submit'>
              Criar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>


      <Modal show={showOrg} onHide={handleCloseOrg}>
        <Form onSubmit={(event) => handleUpdate({ ...getValues(), supplierId: selectedSupplierId }, event)}>
          <Form.Control />
          <Modal.Header closeButton>
            <Modal.Title>Editar Organização</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-3">
              <Col>
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  placeholder="Nome"
                  {...register('name', { required: true })}
                />
                {errors.name && (
                  <span className="label-error">
                    Você precisa informar um Nome.
                  </span>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Form.Label>CNPJ</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="CNPJ"
                  {...register('cnpj')}
                />
                {errors.cnpj && (
                  <span className="label-error">
                    Você precisa informar um CNPJ
                  </span>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Form.Label>Razão Social</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Razão Social"
                  {...register('trade_name')}
                />
                {errors.trade_name && (
                  <span className="label-error">
                    Você precisa informar uma Razão Social
                  </span>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Form.Label>Numero de assentos</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Numeros de assentos"
                  {...register('seats')}
                />
                {errors.trade_name && (
                  <span className="label-error">
                    Você precisa informar um numero de assentos
                  </span>
                )}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseOrg}>
              Fechar
            </Button>
            <Button variant="primary" type='submit'>
              Editar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>


      <Modal show={showConfirm} onHide={handleCloseConfirm}>
        <Modal.Body>
          Tem certeza que deseja excluir {currentSupplier ? currentSupplier.name : ''}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseConfirm}>
            Sair
          </Button>
          <Button variant="danger" onClick={inactvate}>
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Providers
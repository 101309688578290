import React, { useState } from 'react'
import {
    Row,
    Col,
    Card,
    Form,
    Button,
    InputGroup,
    FormControl,
    DropdownButton,
    FloatingLabel,
    Dropdown,
    Table,
    Alert
} from 'react-bootstrap'

import { auth } from "./../../config/firebase";

import { BsPen } from "react-icons/bs";

import { useForm } from 'react-hook-form'
import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import DatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'


registerLocale('pt-br', ptBR)

interface Props {
  eventCode: any,
  eventDataTransformed: any,
  eventTitle: any,
  eventStatus: any
}

export function EventSnippetDetails({
  eventCode,
  eventDataTransformed,
  eventTitle,
  eventStatus
}: Props) {
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        clearErrors,
        getValues,
        reset
    } = useForm()

    const [startDate, setStartDate] = useState(new Date())

    const [hasToken, setHasToken] = useState('')

    const [disableSign, setDisableSign] = useState(false)

    const [eventDue, setEventDue] = useState(new Date())

    const [newUser, setNewUser] = useState({
        login: '',
        password: '',
        set: false
    })

    function string_to_slug(str: any) {
        str = str.replace(/^\s+|\s+$/g, '') // trim
        str = str.toLowerCase()

        // remove accents, swap ñ for n, etc
        const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
        const to = 'aaaaeeeeiiiioooouuuunc------'
        for (let i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
        }

        str = str
            .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, '_') // collapse whitespace and replace by -
            .replace(/-+/g, '_') // collapse dashes

        return str
    }


    const signOut = async () => {
        try {
            await auth.signOut()
            return true
        } catch (error) {
            return false
        }
    };


    return (
<div className="event-action-top">
  


  <div onClick={() => window.location.href = `/events/providers/${eventCode}`} className="square-button call-buttom rounded-sm">
          <i className='custom-icon'>
            <BsPen/>
          </i>
          <span>Editar</span>
      </div>
    <div className="event-action-top-info">
      <div className="square-button rounded-sm">
        <span className="event-info-month">
        {eventDataTransformed.dataTransformMonth}
        </span>
        <span className="event-info-date">
          {eventDataTransformed.dataTransformDay}
        </span>
      </div>
      <div className="square-button event-title rounded-sm">
        <span className="event-info-time">
        {eventDataTransformed.dataTransformTime}
        </span>
        <span className="event-info-title">
          {eventTitle}
        </span>
  
  </div>
  </div> </div>  
    )
}

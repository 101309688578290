import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import InsertLogoModal from './insertLogoModal';
import SelectLogoModal from './selectLogoModal';
import { BASE } from '../../basedata/constants';


import { PiMagnifyingGlassLight } from "react-icons/pi";
import { useForm } from 'react-hook-form';

const EventInformationForm = ({ formData, handleChange }) => {
  const { 
    register, 
    handleSubmit, 
    formState: { errors } 
  } = useForm();
  
  const [showInsertModal, setShowInsertModal] = useState(false);
  const [showSelectModal, setShowSelectModal] = useState(false);

  const handleModalClose = () => {
    setShowSelectModal(false);
  };

  const handleOpenSelectModal = () => {
    setShowSelectModal(true);
  };

  const createEvent = (data: any) => {

    const companyId = sessionStorage.getItem('company')
    const event = formData;

    fetch(`${BASE.api.base_url_localhost}${BASE.api_dashboard.event_create}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': BASE.api.api_key
        },
        body: JSON.stringify({
            "name": event.eventGroup.name,
            "eventDate": data.matchDateTime,
            "status": "active",
            "isActive": true,
            "companyId": event.eventGroup.companyId,
            "eventGroup": event.eventGroup,
            "locationId": '8fe5c94a-5990-48bd-808d-47b24dc5e7f4' //provisorio
        })
    })
        .then((res) => res.json())
        .then((response) => {
          window.location.reload()
        })
        .catch((error) => {
          console.error(error)
        })
  }

  return (
    <div>
      <form onSubmit={handleSubmit(createEvent)}>

        <InputGroup className='mb-3 table-action-search'>
          <div className="">
            <label htmlFor="table-search">
              Data | Hora da Partida:
            </label>
            <input type="datetime-local"
              className="form-control"
              id="matchDateTime"
              value={formData.matchDateTime}
              {...register("matchDateTime", { required: true })}
              onChange={handleChange}
            />
            {errors.matchDateTime && (
              <span className="label-error">
                Você precisa informar uma data válida
              </span>
            )}
          </div>
        </InputGroup>
        <Button
          type="submit"
          className="mb-0 w-100"
        >
          Salvar
        </Button>
      </form>
      <SelectLogoModal showModal={showSelectModal} handleModalClose={handleModalClose} />
    </div>
  );
};

EventInformationForm.propTypes = {
  formData: PropTypes.shape({
    matchDateTime: PropTypes.string.isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default EventInformationForm;


import React, { useState } from 'react'
import {
    Row,
    Col,
    Card,
    Form,
    Button,
    InputGroup,
    FormControl,
    DropdownButton,
    FloatingLabel,
    Dropdown,
    Table,
    Alert
} from 'react-bootstrap'

import { auth } from "./../../config/firebase";

import InputMask from 'react-input-mask';

import { useForm } from 'react-hook-form'
import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import DatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'


registerLocale('pt-br', ptBR)

import 'react-datepicker/dist/react-datepicker.css'

export function ProviderCreate() {
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        clearErrors,
        getValues,
        reset
    } = useForm()

    const [startDate, setStartDate] = useState(new Date())

    const [hasToken, setHasToken] = useState('')

    const [disableSign, setDisableSign] = useState(false)

    const [tags, setTags] = useState<any[]>([])

    function handleKeyDown(e) {
        if (e.key !== 'Enter') return
        const value = e.target.value
        if (!value.trim()) return
        setTags([...tags, value])
        e.target.value = ''
    }

    function removeTag(index) {
        setTags(tags.filter((el, i) => i !== index))
    }

    const [newUser, setNewUser] = useState({
        login: '',
        password: '',
        set: false
    })

    function string_to_slug(str: any) {
        str = str.replace(/^\s+|\s+$/g, '')
        str = str.toLowerCase()

        const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
        const to = 'aaaaeeeeiiiioooouuuunc------'
        for (let i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
        }

        str = str
            .replace(/[^a-z0-9 -]/g, '') 
            .replace(/\s+/g, '_')
            .replace(/-+/g, '_') 

        return str
    }

    const signOut = async () => {
        try {
            await auth.signOut()
            return true
        } catch (error) {
            return false
        }
    };


    const quemta = () => {

        auth.onAuthStateChanged(() => {

            const user = auth.currentUser;
            if (user) {
                const uid = user.uid;
                console.log('minhapika', uid)
            } else {
                console.log('ta na milonga')
            }
        });

    }

    const onSubmit = (data: any) => {
        setDisableSign(true)

        const companyId = sessionStorage.getItem('company')

        fetch(`${BASE.USER.url}${BASE.USER.supplier}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api.api_key
            },
            body: JSON.stringify({
                "name": data.name,
                "trade_name": data.trade_name,
                "seats": data.seats,
                "cnpj": data.cnpj,
                "status": "active",
                "isActive": true,
                "companyId": companyId,
            })
        })
            .then((res) => res.json())
            .then((response) => {
                setDisableSign(false)
                window.location.reload();
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const resetForm = () => {
        setNewUser({
            login: '',
            password: '',
            set: false
        })
        setHasToken('')
        setDisableSign(false)
        reset()
    }

    return (
        <Card>
            <Card.Header>
                <Card.Title as="h5">Cadastrar Camarote</Card.Title>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Row className="mb-3">
                                <Col>
                                    <Form.Label>Cessionário</Form.Label>
                                    <Form.Control
                                        type="name"
                                        placeholder="Nome do cessionario"
                                        {...register('name', { required: true })}
                                    />
                                    {errors.name && (
                                        <span className="label-error">
                                            Você precisa informar um nome.
                                        </span>
                                    )}
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <Form.Label>Assentos</Form.Label>
                                    <Form.Control
                                            type="seats"
                                            placeholder="Numero de assentos"
                                            {...register('seats', { required: true })}
                                        />
                                    {errors.name && (
                                        <span className="label-error">
                                            Você precisa informar um numero de assentos.
                                        </span>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Button
                                            disabled={disableSign}
                                            type="submit"
                                            className="mb-0 w-100"
                                            onClick={() => clearErrors()}
                                        >
                                            Cadastrar
                                        </Button>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>

            </Card.Body>
        </Card>
    )
}
